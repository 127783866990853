@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;900&amp;display=block);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    min-height: 100%;
    background: #2676b2;
}

body {
    font-family: "Work Sans", sans-serif;
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 540px;
    overflow: hidden;
    background: #2676b2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container:before {
    content: "";
    position: absolute;
    top: -50vw;
    left: -50vw;
    width: 200vw;
    height: calc(50% + 50vw);
    background: #d02b46;
    -webkit-transform: rotate(-7deg);
            transform: rotate(-7deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.container.versus:before {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.container:after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 0;
    pointer-events: none;
    -webkit-transition: width 0.3s ease-out, left 0.3s ease-in-out;
    transition: width 0.3s ease-out, left 0.3s ease-in-out;
}

.container.selection1:after {
    left: calc(50% - 1px);
    width: calc(50% + 1px);
    background: #d02b46;
}

.container.selection2:after {
    left: 0;
    width: calc(50% + 1px);
    background: #2676b2;
}

.help_btn,
.close_btn,
.reset,
.volume_btn {
    z-index: 8675309;
    position: absolute;
    border: none;
    background: none;
    top: auto;
    bottom: 1.0rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    color: #fff;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.help_btn {
    display: flex;
    justify-content: center;
    right: 0;
    width: 3rem;
}

@media all and (min-width: 800px) {
    .help_btn {
        right: 0.5rem;
    }
}

.winner1 .help_btn,
.winner2 .help_btn {
    display: none;
}

.close_btn {
    position: fixed;
    display: flex;
    justify-content: center;
    right: 0;
    width: 3rem;
}

.reset {
    right: 3rem;
    opacity: 0;
    pointer-events: none;
}

.reset.visible {
    opacity: 0.5;
    pointer-events: auto;
}

.reset:hover {
    opacity: 1;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.volume_btn {
    display: flex;
    justify-content: center;
    width: 3rem;
    right: auto;
    left: 0.5rem;
    -webkit-transition: none;
    transition: none;
}

.volume_btn--mute {
    width: 2.75rem;
}

.help_btn img,
.close_btn img,
.reset img,
.volume_btn img {
    width: 2rem;
    height: 2rem;
}

.battle {
    position: relative;
    z-index: 8675309;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 540px;
    max-height: 650px;
    padding: 5vh 0;
    overflow-y: auto;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    margin: 0 0 0.75rem;
    text-transform: uppercase;
    letter-spacing: -0.075em;
    line-height: 3.5625em;
    font-weight: bold;
    text-align: center;
    color: #fff;
    font-size: 0.875rem;
}

h1 span {
    position: relative;
    display: block;
    font-size: 5.5em;
}

h1 span:nth-child(2) {
    font-size: 3.375em;
    -webkit-transform: rotate(-1deg);
            transform: rotate(-1deg);
}

h1 span:last-child {
    font-size: 5.25em;
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
}

.song_battle span:last-child {
    font-size: 4em;
    -webkit-transform: rotate(-1deg);
            transform: rotate(-1deg);
}

.battle_artist {
    height: 1.5rem;
    margin: 0 0 1rem;
    line-height: 1.5rem;
    font-size: 1.375rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.rounds {
    display: none;
    opacity: 0;
    height: 1rem;
    margin: 0;
    line-height: 1rem;
    font-size: 0.875em;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.rounds.visible {
    display: block;
    opacity: 1;
}

.btn {
    display: block;
    position: relative;
    overflow: hidden;
    min-width: 9rem;
    height: 3.5rem;
    line-height: 3.5rem;
    padding: 0 1rem;
    border: none;
    border-radius: 0.25rem;
    background: #fff;
    color: #000;
    font-family: inherit;
    font-size: 1.65rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: background-color 0.3s, color 0.3s, opacity 0.3s;
    transition: background-color 0.3s, color 0.3s, opacity 0.3s;
}

@media all and (min-width: 800px) {
    .btn {
        font-size: 2rem;
    }
}

.btn:hover {
    color: #fff;
    background: #222;
}

.btn--link {
    width: 100%;
    background: none !important;
    color: #FFF;
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.85;
    text-transform: none;
    -webkit-transition: color 0.3s, opacity 0.3s;
    transition: color 0.3s, opacity 0.3s;
}

.btn--link:hover {
    opacity: 1;
}

.container.help .btn--link {
    opacity: 0;
    pointer-events: none;
}

.help_section {
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5vh;
    background: #2676b2;
    z-index: 8675310;
    pointer-events: none;
    opacity: 0;
    overflow-y: scroll;
    -webkit-transition: none;
    transition: none;
}

.help_section_container {
    width: 100%;
    padding: 0 0 4.75rem;
    text-align: center;
}

.help_section h1 {
    font-size: 0.625rem;
}

.help_section h2 {
    margin: 0 0 1rem;
    line-height: 1.5rem;
    font-size: 1.375rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.help_section p {
    max-width: 330px;
    font-size: 1.0em;
    line-height: 1.2;
    margin: 0 auto 16px;
    color: #FFF;
}

.help_section strong {
    font-weight: 600;
}

.help_section a {
    color: #FFF;
}

.help_section p + h2 {
    margin-top: 48px;
}

.container.help .help_section {
    pointer-events: auto;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
}

.btn--start,
.btn--auth {
    width: 100%;
    cursor: pointer;
    pointer-events: all;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.loader {
    display: none;
    position: absolute;
    top: 0.5rem;
    right: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 auto;
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #ddbf7d;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ddbf7d transparent transparent transparent;
}
.loader div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}
.loader div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.loader div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

button.loading {
    text-indent: -9999px;
}

button.loading .loader {
    display: inline-block;
}

input {
    max-width: 80%;
    padding: 0.5rem;
    margin: 0 auto 2.75rem;
    border: none;
    background: #fff;
    color: #000;
    font-family: inherit;
    font-size: 2rem;
    letter-spacing: -0.075em;
}

.artists {
    z-index: 8675308;
    width: 12rem;
    height: 1rem;
    margin: 0 auto 1rem;
    opacity: 0;
    pointer-events: none;
    -webkit-perspective: 800px;
            perspective: 800px;
}

.artists.visible {
    flex: 0 0 12rem;
    height: 12rem;
    opacity: 1;
    pointer-events: auto;
}

.versus .artists.visible {
    height: 35vw;
    max-height: 12rem;
    flex: 1 0 35vw;
}

.artists--myb {
    flex: 0 0 12rem;
    height: 12rem;
    opacity: 1;
}

@media all and (min-width: 800px) {
    .artists.visible,
    .artists--myb {
        flex: 0 0 14rem;
        width: 14rem;
        height: 14rem;
    }
}

.artists button {
    position: absolute;
    top: calc(100% + 0.5rem);
    width: 100%;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.artists button.visible {
    opacity: 1;
}

.play {
    width: 100%;
    max-width: 12rem;
    height: 175px;
}

.play--small {
    height: 138px;
}

@media all and (min-width: 800px) {
    .play {
        max-width: 14rem;
    }
}

.related {
    z-index: 8675309;
    display: none;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 1rem;
    width: 36rem;
    max-width: 100%;
    padding: 0 0 0 5.5rem;
    margin: 0 auto;
}

.related:before,
.related:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 5rem;
    text-transform: uppercase;
    letter-spacing: -0.0375rem;
    line-height: 1.1875rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.related:before {
    content: "Top";
    padding: 1.25rem 0.375rem 0;
    font-size: 1.75rem;
}

.related:after {
    content: "picks";
    padding: 2.4375rem 0.375rem 0;
    font-size: 1.375rem;
    -webkit-transform: rotate(-1deg);
            transform: rotate(-1deg);
}

.played .related {
    display: block;
}

.played .related:before {
    content: "Play";
}
.played .related:after {
    content: "again";
}

.related ul {
    display: flex;
    max-width: 30rem;
    overflow: hidden;
    overflow-x: auto;
}

.related .card {
    position: relative;
    flex: 0 0 5rem;
    height: 5rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.related .card .info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    background: #fff;
    color: #000;
    opacity: 0.75;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.related .card:hover .info {
    opacity: 1;
}

.card {
    position: absolute;
    height: 100%;
    border-radius: 0.25rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transition: all 0.3s ease-out, -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.3s ease-out, -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.3s ease-out, transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.3s ease-out, transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card * {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.card .front,
.card .back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
}

.card img {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    object-fit: cover;
}

.card .back {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #fff;
    font-size: 2rem;
    font-weight: bold;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.card .back img {
    width: 2.0rem;
    height: auto;
    margin: auto;
}

.artist {
    width: 100%;
}

.artist .back {
    color: #ddbf7d;
    font-size: min(6vw, 2rem);
}

.container.starting h1,
.container.versus h1 {
    font-size: 0.625rem;
    -webkit-transition: font-size 0.3s, -webkit-transform 0.3s;
    transition: font-size 0.3s, -webkit-transform 0.3s;
    transition: font-size 0.3s, transform 0.3s;
    transition: font-size 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.container.starting .btn--start,
.container.versus .btn--start {
    display: none;
}

.container.versus .artists.visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15vw;
    margin: 0 auto;
}

.container.versus .artists .artist {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    max-width: 5rem;
    max-height: 5rem;
    height: 15vw;
}

.container.intro:before,
.container.help:before {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    top: -200vh;
}

.container .battle_artist {
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}

.container .artists {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.container.intro .battle h1,
.container.help .battle h1 {
    -webkit-transform: translateY(-200vh);
            transform: translateY(-200vh);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.container.intro .battle_artist,
.container.help .battle_artist {
    -webkit-transform: translateY(-200vh);
            transform: translateY(-200vh);
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}

.container.intro .artists,
.container.help .artists {
    -webkit-transform: translateY(-200vh);
            transform: translateY(-200vh);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.container.intro .btn--start,
.container.help .btn--start,
.container.intro .btn--auth,
.container.help .btn--auth,
.container.intro .btn--link,
.container.help .btn--link,
.container.intro .help_btn,
.container.help .help_btn,
.container.intro .volume_btn,
.container.help .volume_btn {
    opacity: 0;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s;
}

.container.intro .songs,
.container.help .songs {
    -webkit-transform: translateY(-200vh);
            transform: translateY(-200vh);
}

.songs {
    z-index: 8675309;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 35vw;
    height: 35vw;
    max-width: 12rem;
    max-height: 12rem;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
}

.song {
    width: 35vw;
    height: 35vw;
    max-width: 12rem;
    max-height: 12rem;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 3rem 2px rgba(236, 235, 232, 0.75);
    cursor: pointer;
}

@media all and (min-width: 800px) {
    .song.active {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

.song.defeated {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.song.selected {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.winner1 .song1,
.winner2 .song2 {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}

.winner1 .action,
.winner2 .action {
    display: none !important;
}

.song .front {
    padding: 0.5rem;
}

.song .back {
    font-size: 5rem;
}

.song__title {
    height: 1.75rem;
    line-height: 0.875rem;
    margin: 0 auto 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    color: #fff;
    pointer-events: none;
}

.song .action,
.song .artwork {
    display: block;
    position: absolute;
}

.song .action {
    width: calc(100% - 10.5rem);
    min-width: 1.0rem;
    height: auto;
    top: auto;
    right: 0.5rem;
    bottom: 0.5rem;
    left: auto;
    margin: 0;
}

.song .artwork {
    top: 2.75rem;
    right: 0;
    left: 0;
    width: calc(100% - 5.5rem);
    height: calc(100% - 5.5rem);
    border-radius: 50%;
    padding: 2px;
    margin: 0 auto 0.75rem;
    border: solid 2px #ddbf7d;
    pointer-events: none;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.song.active .artwork {
    -webkit-animation: pulse 1.5s infinite;
            animation: pulse 1.5s infinite;
}

.song.active .action {
    opacity: 0;
}

.song audio {
    display: none;
}

.song1,
.song1 .back {
    background: #d02b46;
}

.song1 {
    left: 0;
}

.song2,
.song2 .back {
    background: #2676b2;
}

.song2 {
    right: 0;
}

.container.versus .songs {
    opacity: 1;
    pointer-events: auto;
}

.container.versus .song1 {
    left: -27.5vw;
}

.container.versus .song2 {
    right: -27.5vw;
}

.container.winner1 .song1 {
    left: 0;
}

.container.winner2 .song2 {
    right: 0;
}

.container.selection1 .song2 {
    right: -150vw;
}

.container.selection2 .song1 {
    left: -150vw;
}

.hidden {
    display: none !important;
}

.share,
.social_share {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    line-height: 1rem;
    font-size: 0.875em;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 0;
    transition: opacity 0;
    -webkit-transition-delay: 0;
            transition-delay: 0;
}

.container.winner1 .social_share,
.container.winner2 .social_share {
    pointer-events: auto;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    -webkit-transition-delay: 0.8s;
            transition-delay: 0.8s;
}

.container.winner1 .volume_btn,
.container.winner2 .volume_btn {
    display: none !important;   
}

.share div,
.social_share div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.share a,
.social_share a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40px;
    height: 28px;
    color: #fff;
}

.share a + a,
.social_share a + a {
    width: 36px;
}

.share svg,
.social_share svg {
    display: block;
    height: 28px;
}

.share {
    pointer-events: auto;
    opacity: 1;
    margin-top: 48px;
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 hsla(24, 37%, 69%, 0.8);
    }
    70% {
        box-shadow: 0 0 0 16px hsla(24, 37%, 69%, 0.0);
    }
    100% {
        box-shadow: 0 0 0 0 hsla(24, 37%, 69%, 0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 hsla(24, 37%, 69%, 0.8);
    }
    70% {
        box-shadow: 0 0 0 16px hsla(24, 37%, 69%, 0.0);
    }
    100% {
        box-shadow: 0 0 0 0 hsla(24, 37%, 69%, 0);
    }
}

@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes enter {
    0% {
        opacity: 1;
        -webkit-transform: translateY(-200vh);
                transform: translateY(-200vh);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes enter {
    0% {
        opacity: 1;
        -webkit-transform: translateY(-200vh);
                transform: translateY(-200vh);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes startRotate {
    0% {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
    100% {
        -webkit-transform: rotate(-7deg);
                transform: rotate(-7deg);
    }
}

@keyframes startRotate {
    0% {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
    100% {
        -webkit-transform: rotate(-7deg);
                transform: rotate(-7deg);
    }
}

